$(document).ready(function() {
	
	$('#dismiss, .overlay').on('click', function () {
        $('#sidebar').removeClass('active');
        $('.overlay').removeClass('active');
    });

    $('#sidebarCollapse').on('click', function () {
        $('#sidebar').addClass('active');
        $('.overlay').addClass('active');
        $('a[aria-expanded=true]').attr('aria-expanded', 'false');
	});
});

$(document).scroll(function() {
	
	let y = $(this).scrollTop();
	if (y > 400) {
		$('.floatingSideMenu').fadeIn();
		
		$('a.smooth-scroll-spy').on('click', function(e) {
			let position = $(this);
			$('html, body').stop().animate({
				scrollTop: $(position.attr('href')).offset().top - 111
			}, 1000, 'easeInOutExpo');
			e.preventDefault();
		});
	} else {
		$('.floatingSideMenu').fadeOut();
	}
});