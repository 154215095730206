import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
// STATE MANAGEMENT (VUEX)
export default new Vuex.Store({
    state: {
        isLoading: false,
        isCheckout: false,
        status: '',
        token_member: localStorage.getItem('token_member') || '',
        name_member : localStorage.getItem('name_member') || '',
        photo_profile : localStorage.getItem('photo_profile') || '',
        account_type : localStorage.getItem('account_type') || '',
        data_checkout: '',
        kupon_used: '',
    },
    mutations: {
        loading(state, isLoading) {
            state.isLoading = isLoading
        },
        auth_request(state){
            state.status = 'loading'
        },
        auth_success(state, token_member, name_member, photo_profile, account_type){
            state.status = 'success'
            // KETIKA BERHASIL LOGIN, TERJADI INISIALISASI PADA state token_member, name_member, photo_profile, account_type
            state.token_member = token_member
            state.name_member = name_member
            state.photo_profile = photo_profile
            state.account_type = account_type
        },
        auth_error(state){
            state.status = 'error'
        },
        logout(state){
            state.status = ''
            // KETIKA LOGOUT, state token_member, name_member, photo_profile, account_type AKAN DIHAPUS ISINYA
            state.token_member = ''
            state.name_member = ''
            state.photo_profile = ''
            state.account_type = ''
        },
        change_profile(state, name_member, photo_profile){ 
            state.name_member = name_member
            state.photo_profile = photo_profile
        },
        do_checkout(state, data_checkout){
            state.isCheckout = true
            state.data_checkout = data_checkout
        },
        kupon_name(state, kupon) {
            state.kupon_used = kupon
        },
    },
    actions: {
        // AKSI LOGIN : MENGATUR TENTANG BAGAIMANA PENGGUNA DAPAT MASUK KE SISTEM
        login({commit}, formData){
            return new Promise((resolve, reject) => {
                commit('auth_request')  // PANGGIL FUNGSI auth_request PADA mutations

                /*  [API POST] 
                *   MENCOBA LOGIN DENGAN FORM INPUTAN BERUPA email & password (berasal dari file 'views/login.vue')
                */
                window.axios.post('/login', formData, {
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        }
                    }
                )
                .then(response => {
                    if(response.data.success) {
                        // KETIKA response.data.success BERNILAI TRUE MAKA FUNGSI BLOK INI AKAN DIJALANKAN

                        /*  [API GET] 
                        *  MENGAMBIL DATA LENGKAP PROFILE USER KETIKA BERHASIL LOGIN
                        */
						window.axios.get('/me/profile', {
								headers: {
									"token": response.data.data.access_token,
								}
							}
						)
						.then(response2 => {
							const token_member = response.data.data.access_token
							const name_member = response2.data.fullname
							const photo_profile = response2.data.photo
							const account_type = response2.data.account_type
							
							localStorage.setItem('token_member', token_member)
							localStorage.setItem('name_member', name_member)
							localStorage.setItem('photo_profile', photo_profile)
							localStorage.setItem('account_type', account_type)
							
							commit('auth_success', token_member, name_member, photo_profile, account_type)  // PANGGIL FUNGSI auth_success PADA mutations DENGAN PARAMETER
							resolve(name_member)    // MENGEMBALIKAN nama_member KE FILE 'views/Login.vue'
						})
                        // KETIKA TERJADI KESALAHAN KODE/SYNTAX PADA BLOK then DI ATAS MAKA FUNGSI (catch) DI BAWAH AKAN DIJALANKAN
						.catch(error => {
							commit('auth_error')
							localStorage.removeItem('token_member')
							localStorage.removeItem('name_member')
							localStorage.removeItem('photo_profile')
							localStorage.removeItem('account_type')
							reject(error)   // MENGEMBALIKAN error KE FILE 'views/Login.vue'
						})
                    }
                    else {
                        // KETIKA response.data.success BERNILAI FALSE MAKA FUNGSI BLOK INI AKAN DIJALANKAN
                        localStorage.removeItem('token_member')
                        localStorage.removeItem('name_member')
                        localStorage.removeItem('photo_profile')
                        localStorage.removeItem('account_type')
                        reject(response.data.message)  // MENGEMBALIKAN PESAN ERROR DARI API KE FILE 'views/Login.vue'
                    }
                })
                // KETIKA TERJADI KESALAHAN KODE/SYNTAX PADA BLOK then DI ATAS MAKA FUNGSI (catch) DI BAWAH AKAN DIJALANKAN
                .catch(error => {
                    commit('auth_error')
                    localStorage.removeItem('token_member')
                    localStorage.removeItem('name_member')
                    localStorage.removeItem('photo_profile')
                    localStorage.removeItem('account_type')
                    reject(error)
                })
            })
        },
        // AKSI REGISTER : MENGATUR TENTANG BAGAIMANA PENGGUNA MENDAFTARKAN DIRI KE SISTEM
        register({commit}, formData){
            return new Promise((resolve, reject) => {
                commit('auth_request')  // PANGGIL FUNGSI auth_request PADA mutations

                /*  [API POST] 
                *   MENCOBA REGISTER DENGAN FORM INPUTAN (berasal dari file 'views/Register.vue')
                */
                window.axios.post('/register', formData, {
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        }
                    }
                )
                .then(response => {
                    if(response.data.success) {
                        // KETIKA response.data.success BERNILAI TRUE MAKA FUNGSI BLOK INI AKAN DIJALANKAN
                        localStorage.removeItem('token_member')
                        localStorage.removeItem('name_member')
						localStorage.removeItem('photo_profile')
                        localStorage.removeItem('account_type')
                        resolve(response.data.message)  // MENGEMBALIKAN PESAN SUKSES DARI API KE FILE 'views/Register.vue'
                    }
                    else {
                        // KETIKA response.data.success BERNILAI FALSE MAKA FUNGSI BLOK INI AKAN DIJALANKAN
                        localStorage.removeItem('token_member')
                        localStorage.removeItem('name_member')
						localStorage.removeItem('photo_profile')
                        localStorage.removeItem('account_type')
                        reject(response.data.message)  // MENGEMBALIKAN PESAN ERROR DARI API KE FILE 'views/Register.vue'
                    }
                })
                // KETIKA TERJADI KESALAHAN KODE/SYNTAX PADA BLOK then DI ATAS MAKA FUNGSI (catch) DI BAWAH AKAN DIJALANKAN
                .catch(error => {
                    commit('auth_error', error)
                    localStorage.removeItem('token_member')
                    localStorage.removeItem('name_member')
					localStorage.removeItem('photo_profile')
                    localStorage.removeItem('account_type')
                    reject(error)
                })
            })
        },
        // AKSI LOGOUT : FUNGSI YANG DIJALANKAN KETIKA INGIN KELUAR DARI SISTEM
        logout({commit}){
            return new Promise((resolve, reject) => {
                commit('logout')  // PANGGIL FUNGSI logout PADA mutations

                // MENGHAPUS SEMUA ITEM PADA LOCAL STORAGE BROWSER YANG BERSANGKUTAN DENGAN PENGGUNA
                localStorage.removeItem('token_member')
                localStorage.removeItem('name_member')
				localStorage.removeItem('photo_profile')
                localStorage.removeItem('account_type')
                localStorage.removeItem('prod_compare_0')
                localStorage.removeItem('prod_compare_1')
                localStorage.removeItem('prod_compare_2')
                resolve()
            })
        },
        // AKSI UPDATE PROFILE : FUNGSI UNTUK MENGUPDATE PROFILE PENGGUNA
        updateProfile({commit, state}, formData){
            return new Promise((resolve, reject) => {
                
                /*  [API POST] 
                *   MENGUBAH DATA DIRI PENGGUNA BERDASARKAN DENGAN FORM INPUTAN (berasal dari file 'components/MenuProfile.vue')
                */
                window.axios.post('/me/profile/edit', formData, {
                    headers: {
						"Content-Type": "multipart/form-data",
			    		"token": state.token_member,
                    }
                 })
 			    .then((response) => {
					/*  [API GET] 
                    *   MENGAMBIL DATA LENGKAP PROFILE USER KETIKA BERHASIL MENGUBAH PROFIL
                    */
					window.axios.get('/me/profile', {
							headers: {
								"token": state.token_member,
							}
						}
					)
					.then(response2 => {
						const name_member = response2.data.fullname
						const photo_profile = response2.data.photo
					
						localStorage.setItem('name_member', name_member)
						localStorage.setItem('photo_profile', photo_profile)
						commit('change_profile', name_member, photo_profile)    // PANGGIL FUNGSI change_profile PADA mutations DENGAN PARAMETER
						
						resolve(response2)  // MENGEMBALIKAN DATA PROFILE PENGGUNA KE FILE 'components/MenuProfile.vue'
					})
                    // KETIKA TERJADI KESALAHAN KODE/SYNTAX PADA BLOK then DI ATAS MAKA FUNGSI (catch) DI BAWAH AKAN DIJALANKAN
					.catch(error => {
						reject(error)   
					})
 			    })
                 // KETIKA TERJADI KESALAHAN KODE/SYNTAX PADA BLOK then DI ATAS MAKA FUNGSI (catch) DI BAWAH AKAN DIJALANKAN
 			    .catch((error) => {
                    reject(error)
                })
            })
        },
        // AKSI FITUR CHECKOUT : FUNGSI UNTUK MENAMPILKAN PROFUK SAAT CHECKOUT
        checkout({commit, state}, formData) {
            return new Promise((resolve, reject) => {
                
                /*  [API POST] 
                *   MENGUBAH DATA DIRI PENGGUNA BERDSARKAN DENGAN FORM INPUTAN (berasal dari file 'components/MenuProfile.vue')
                */
                window.axios.post('/checkout', formData, {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                        "token": state.token_member,
                    }
                })
                .then(response => {
                    if(response.data.success) {
                        // KETIKA response.data.success BERNILAI TRUE MAKA FUNGSI BLOK INI AKAN DIJALANKAN

                        const data_checkout = response.data.data
                        const kupon = formData.get('kupon_code')
                        commit('do_checkout', data_checkout)    // PANGGIL FUNGSI do_checkout PADA mutations DENGAN PARAMETER data_checkout
                        commit('kupon_name', kupon)    // PANGGIL FUNGSI kupon_name PADA mutations DENGAN PARAMETER kupon
                        resolve(response.data.message)   // MENGEMBALIKAN PESAN SUKSES DARI API 
                    }
                    else {
                        // KETIKA response.data.success BERNILAI FALSE MAKA FUNGSI BLOK INI AKAN DIJALANKAN
                        reject(response.data.message)   // MENGEMBALIKAN PESAN ERROR DARI API 
                    }
                })
                // KETIKA TERJADI KESALAHAN KODE/SYNTAX PADA BLOK then DI ATAS MAKA FUNGSI (catch) DI BAWAH AKAN DIJALANKAN
                .catch(error => {
                    reject(error)  // MENGEMBALIKAN PESAN ERROR DARI API 
                })
            })
        },
    },
    getters : {
        authStatus: state => state.status,
        isLoading: state => state.isLoading,
        isCheckout: state => state.isCheckout,
        isLoggedIn: state => !!state.token_member,
        token_member: state => state.token_member,
        name_member: state => state.name_member,
        photo_profile: state => state.photo_profile,
        account_type: state => state.account_type,
        data_checkout: state => state.data_checkout,
        kupon_used: state => state.kupon_used,
    }
})