<template>
  <footer>
    <div class="container mt-3">
      <div class="row">
        <div class="col-md-8 offset-md-2">
          <div class="row no-gutters d-flex align-items-center my-5 text-center pl-lg-5 ml-lg-4">
            <div class="col-md-3 text-right pr-5 d-none d-md-block d-lg-block">
              <img src="@/assets/images/newletter.png" class="w-100" alt="" />
            </div>
            <div class="col-md-9 text-md-left">
              <h5>Info Promo & Diskon Spesial</h5>
              <span>Dapatkan info promo serta diskon spesial dari Dunia Murah. <br />Tidak ada spam, kami menghargai privasi Anda.</span>
              <form @submit.prevent="subscribe">
                <div class="form-row align-items-center">
                  <div class="col-md-8 my-1">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <div class="input-group-text"><i class="fa fa-envelope text-secondary"></i></div>
                      </div>
                      <input type="email" v-model="email_subscribe" name="email_subscribe" class="form-control" placeholder="Masukan email Anda di sini" required />
                    </div>
                  </div>
                  <div class="col-md-auto my-1"><button type="submit" class="btn btn-primary w-100">Subscribe</button></div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid py-2">
      <div class="row mx-md-4">
        <div class="col-md-2 col-6">
          <h6 class="my-md-3">Panduan</h6>
          <ul class="p-0">
            <li class="list-group-item border-0 p-0 bg-transparent">
              <router-link to="/static/panduan-belanja" class="text-decoration-none text-secondary"><small>Cara Berbelanja</small></router-link>
            </li>
            <li class="list-group-item border-0 p-0 bg-transparent">
              <router-link to="/static/pembayaran" class="text-decoration-none text-secondary"><small>Pembayaran</small></router-link>
            </li>
            <li class="list-group-item border-0 p-0 bg-transparent">
              <router-link to="/static/pengembalian" class="text-decoration-none text-secondary"><small>Pengembalian</small></router-link>
            </li>
          </ul>
        </div>
        <div class="col-md-2 col-6">
          <h6 class="my-md-3">Informasi</h6>
          <ul class="p-0">
            <li class="list-group-item border-0 p-0 bg-transparent">
              <router-link to="/static/pengiriman" class="text-decoration-none text-secondary"><small>Pengiriman</small></router-link>
            </li>
            <li class="list-group-item border-0 p-0 bg-transparent">
              <router-link to="/static/privacy-policy" class="text-decoration-none text-secondary"><small>Kebijakan Privasi</small></router-link>
            </li>
            <li class="list-group-item border-0 p-0 bg-transparent">
              <router-link to="/static/syarat-ketentuan" class="text-decoration-none text-secondary"><small>Syarat & Ketentuan</small></router-link>
            </li>
            <li class="list-group-item border-0 p-0 bg-transparent">
              <router-link to="/static/tentang-kami" class="text-decoration-none text-secondary"><small>Tentang Kami</small></router-link>
            </li>
            <li class="list-group-item border-0 p-0 bg-transparent">
              <router-link to="/static/karir" class="text-decoration-none text-secondary"><small>Karir</small></router-link>
            </li>
            <li class="list-group-item border-0 p-0 bg-transparent">
              <router-link to="/static/call-center" class="text-decoration-none text-secondary"><small>Call Center</small></router-link>
            </li>
            <li class="list-group-item border-0 p-0 bg-transparent">
              <router-link to="/static/customer-service" class="text-decoration-none text-secondary"><small>Customer Service</small></router-link>
            </li>
          </ul>
        </div>
        <div class="col-md-4 col-12">
          <h6 class="my-md-3">Layanan Pelanggan</h6>
          <small>
            <div class="row d-flex align-items-center">
              <div class="col-md-1 col-2 py-1 pr-0 text-right"><i class="fa fa-user-clock fa-lg text-info"></i></div>
              <div class="col-md-11 col-10 py-1">
                <span>
                  Layanan Pelanggan:<br />
                  <ul class="list-no-bullet">
                    <li>Senin s/d Jumat: 08:00 - 17:00</li>
                    <li>Sabtu: 08:30 - 14:30</li>
                    <li>Minggu & hari libur nasional: libur</li>
                  </ul>
                </span>
              </div>

              <div class="col-md-1 col-2 py-1 pr-0 text-right"><i class="fa fa-map-marker-alt fa-lg text-info"></i></div>
              <div class="col-md-11 col-10 py-1"><span>Komplek Rukan Gading Bukit Indah Blok L No. 16 Jl. Bukit Gading Raya, Kelapa Gading Barat Jakarta Utara 14240</span></div>

              <div class="col-md-1 col-2 py-1 pr-0 text-right"><i class="fa fa-clock fa-lg text-info"></i></div>
              <div class="col-md-11 col-10 py-1"><span>Penjualan Online 24 Jam</span></div>

              <div class="col-md-1 col-2 py-1 pr-0 text-right"><i class="fa fa-phone fa-lg text-info"></i></div>
              <div class="col-md-11 col-10 py-1"><span>+62 21 24525877</span></div>

              <div class="col-md-1 col-2 py-1 pr-0 text-right"><i class="fab fa-whatsapp fa-lg text-info" style="font-size: 21px"></i></div>
              <div class="col-md-11 col-10 py-1"><span>+62 813-1000-5877</span></div>

              <div class="col-md-1 col-2 py-1 pr-0 text-right"><i class="fa fa-envelope fa-lg text-info"></i></div>
              <div class="col-md-11 col-10 py-1"><span>info@duniamurah.com</span></div>
            </div>
          </small>
        </div>
        <div class="col-md-4 text-center">
          <div class="row">
            <div class="col-md-12 col-12 p-sm-0 py-2 text-left">
              <h6 class="my-md-3">Layanan Pengiriman</h6>
              <div>
                <img src="@/assets/images/jne-logo.png" style="width: 90px;" class="mx-2 mb-2" />
                <img src="@/assets/images/tiki-logo.png" style="width: 90px;" class="mx-2 mb-2" />
                <img src="@/assets/images/pos-indo-logo.png" style="width: 90px;" class="mx-2 mb-2" />
                <img src="@/assets/images/j&t-logo.png" style="width: 90px;" class="mx-2 mb-2" />
                <img src="@/assets/images/wahana-logo.png" style="width: 90px;" class="mx-2 mb-2" />
              </div>
            </div>
            <div class="col-md-12 col-12 p-sm-0 py-2 text-left mt-3">
              <h6 class="my-md-3">Metode Pembayaran</h6>
              <div>
                <img src="@/assets/images/bank-bca.png" style="width: 80px;" class="mx-2 mb-2 border round shadow" />
                <img src="@/assets/images/bank-bni.png" style="width: 80px;" class="mx-2 mb-2 border round shadow" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="my-2" style="width: 96%;" />
      <div class="row mx-md-4 mt-3 pt-4 pb-3">
        <!-- <div class="col-md-1"></div> -->
        <div style="padding-top: 40px; padding-left: 20px;">
          <img src="@/assets/images/logo.png" style="width: 150px;" class="mr-3 d-none d-lg-block" />
        </div>
        <!-- <div class="col text-justify" style="padding-right: 20px;"> -->
        <div class="col-md-4 col-12 text-justify d-flex">
          <span class="mr-4">
            <h6>Duniamurah : Belanja online Lebih Mudah!</h6>
            <small
              >Berawal dari semangat untuk mendukung pertumbuhan UKM dan memudahkan masyarakat mencari barang kebutuhan secara online, kini telah hadir duniaMurah.com, Toko online yang membantu Anda menemukan produk dengan Harga
              Terbaik.</small
            >
          </span>
        </div>
        <div class="col-md-3 col-12">
          <div class="row">
            <div class="col-md-12 col-12 p-sm-0 py-2 text-left">
              <h6 class="mb-md-3">Temukan Kami Di</h6>
              <a href="https://twitter.com/duniamurah/" target="_blank" class="btn btn btn-outline-info mx-2 mb-2"><i class="fab fa-twitter fa-lg" style="width: 28px;"></i></a>
              <a href="https://www.facebook.com/duniamurahindonesia/" target="_blank" class="btn btn btn-outline-primary mx-2 mb-2"><i class="fab fa-facebook fa-lg" style="width: 28px;"></i></a>
              <a href="https://www.instagram.com/duniamurahindonesia/" target="_blank" class="btn btn btn-outline-danger mx-2 mb-2"><i class="fab fa-instagram fa-lg" style="width: 28px;"></i></a>
              <a href="https://youtube.com/" target="_blank" class="btn btn btn-outline-danger mx-2 mb-2"><i class="fab fa-youtube fa-lg" style="width: 28px;"></i></a>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-12">
          <div class="row">
            <div class="col-md-12 col-12 p-sm-0 py-2 text-left">
              <h6 class="mb-md-3">Unduh Aplikasi Kami Melalui</h6>
              <a href="https://play.google.com/store/apps/details?id=com.duniamurah.duniamurah"><img src="@/assets/images/google-play.png" style="width: 130px;" class="mx-2 mb-2 shadow"/></a>
              <img src="@/assets/images/app-store.png" style="width: 130px;" class="mx-2 mb-2 shadow" />
              <img src="@/assets/images/SVG_QR_Code.png" style="width: 130px;" class="mx-2 mb-2 shadow" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid py-2 text-center border-top">
      <small>Copyright &copy; 2021 duniamurah.com &nbsp; | &nbsp; All Rights Reserved</small>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      email_subscribe: "",
    };
  },
  methods: {
    subscribe() {
      let formData = new FormData();
      formData.append("email", this.email_subscribe);

      window.axios
        .post("http://devdm.anyoneserver.com/subscription", formData, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then((response) => {
          this.email_subscribe = "";
          this.$swal({
            toast: true,
            timer: 6000,
            position: "top-end",
            showConfirmButton: false,
            icon: "success",
            html: '<p class="mb-0 mx-3 text-left">Email Anda telah kami catat!<br />Nantikan info promo & diskon spesial dari kami.</p>',
            customClass: {
              container: "mt-5 pt-5",
              popup: "border border-success mt-5 px-4",
            },
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
