import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store.js'

Vue.use(VueRouter)

const routes = [
  // HALAMAN NON-MEMBER
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/product-detail/:id-:name',
    name: 'ProductDetail',
    component: () => import('../views/ProductDetail.vue')
  },
  {
    path: '/product-list',
    name: 'ProductList',
    component: () => import('../views/ProductList.vue'),
    props: route => ({ query: route.query.q })
  },
  {
    path: '/product/compare',
    name: 'CompareProducts',
    component: () => import('../views/Compare.vue'),
  },
  {
    path: '/brand',
    name: 'Brand',
    component: () => import('../views/Brand.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Register.vue')
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('../views/ForgotPassword.vue')
  },
  {
    path: '/promo',
    name: 'Promo',
    component: () => import('../views/Promo.vue')
  },
  
  // HALAMAN MEMBER , DAPAT DIAKSES KETIKA TELAH LOGIN
  {
    path: '/member/shopping-cart',
    name: 'ShoppingCartMember',
    component: () => import('../views/member/ShoppingCart.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/member/checkout',
    name: 'CheckoutMember',
    component: () => import('../views/member/Checkout.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/member/favorite',
    name: 'FavoritetMember',
    component: () => import('../views/member/Favorite.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/member/profile',
    name: 'ProfileMember',
    component: () => import('../views/member/ProfileContainer.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/member/purchase',
    name: 'PembelianMember',
    component: () => import('../views/member/PurchaseContainer.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/member/bargaining',
    name: 'NegoMember',
    component: () => import('../views/member/BargainingContainer.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/member/review',
    name: 'ReviewMember',
    component: () => import('../views/member/ReviewContainer.vue'),
    meta: {
      requiresAuth: true
    }
  },

  // HALAMAN STATIS , HALAMAN YANG BERISI TULISAN STATIS
  {
    path: '/static/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import('../views/static/PrivacyPolicy.vue')
  },
  {
    path: '/static/panduan-belanja',
    name: 'PanduanBelanja',
    component: () => import('../views/static/PanduanBelanja.vue')
  },
  {
    path: '/static/pembayaran',
    name: 'Pembayaran',
    component: () => import('../views/static/Pembayaran.vue')
  },
  {
    path: '/static/pengembalian',
    name: 'Pengembalian',
    component: () => import('../views/static/Pengembalian.vue')
  },
  {
    path: '/static/pengiriman',
    name: 'Pengiriman',
    component: () => import('../views/static/Pengiriman.vue')
  },
  {
    path: '/static/tentang-kami',
    name: 'TentangKami',
    component: () => import('../views/static/TentangKami.vue')
  },
  {
    path: '/static/kelebihan-kami',
    name: 'KelebihanKami',
    component: () => import('../views/static/KelebihanKami.vue')
  },
  {
    path: '/static/karir',
    name: 'Karir',
    component: () => import('../views/static/Karir.vue')
  },
  {
    path: '/static/syarat-ketentuan',
    name: 'SyaratDanKetentuan',
    component: () => import('../views/static/SyaratDanKetentuan.vue')
  },
  {
    path: '/static/call-center',
    name: 'CallCenter',
    component: () => import('../views/static/CallCenter.vue')
  },
  {
    path: '/static/customer-service',
    name: 'CustomerService',
    component: () => import('../views/static/CustomerService.vue')
  },
  {
    path: '/static/website',
    name: 'Website',
    component: () => import('../views/static/Website.vue')
  },

  // KETIKA path URL YANG DITUJU TIDAK DITEMUKAN MAKA AKAN DIARAHKAN KE '/' atau Komponen Home
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  // CEK APAKAH HALAM YANG AKAN DIAKSES MEMBUTUHKAN KOMPONEN meta : requiresAuth
  if(to.matched.some(record => record.meta.requiresAuth)) {
    // JIKA HALAMAN MEMBUTUHKAN KOMPONEN meta : requiresAuth, MAKA CEK DATA PENGGUNA APAKAH MEMILIKI AKSES ATAU TELAH LOGIN?
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/login')
  }
  else {
    next()
  }
})

export default router
