"use strict";

import Vue from "vue";
import axios from "axios";
import store from "../store.js";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

// const data_member = localStorage.getItem('data_member')
// if (data_member) {
//   try {
//     axios.defaults.headers.common['Authorization'] = JSON.parse(data_member).access_token
//   }
//   catch(e) {
//     console.log('Failed Load Token!')
//   }
// }

let config = {
  // baseURL: "http://devdm.anyoneserver.com", // UNTUK DEVELOPMENT
  baseURL: "https://api.duniamurah.com", // UNTUK PRODUCTION
  timeout: 20000, // WAKTU MAKSIMUM YANG DIBERIKAN KETIKA AKSES API
  // withCredentials: true, // CEK cross-site Access-Control
};

const _axios = axios.create(config);

// FUNGSI YANG BELAJAN KETIKA REQUEST KE API
_axios.interceptors.request.use(
  function(config) {
    store.commit("loading", true); // UNTUK MENJALANKAN ANIMASI LOADING SAAT PEMANGGILAN API
    return config;
  },
  function(error) {
    store.commit("loading", false); // UNTUK MENGHENTIKAN ANIMASI LOADING SAAT API ERROR
    return Promise.reject(error);
  }
);

// FUNGSI YANG BELAJAN KETIKA API MEMBERIKAN RESPONSE
_axios.interceptors.response.use(
  function(response) {
    store.commit("loading", false); // UNTUK MENGHENTIKAN ANIMASI LOADING SAAT RESPONSE API SUKSES
    return response;
  },
  function(error) {
    store.commit("loading", false); // UNTUK MENGHENTIKAN ANIMASI LOADING SAAT RESPONSE API ERROR
    return new Promise(function(resolve, reject) {
      // MENCEGAH PENGGUNA UNTUK MENGAKSES HALAMAN TAMPA MELALUI MENU LOGIN
      if (error.status === 401 && error.config && !error.config.__isRetryRequest) {
        store.dispatch(logout);
      }
      throw error;
    });
  }
);

Plugin.install = function(Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      },
    },
    $axios: {
      get() {
        return _axios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
