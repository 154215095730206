<template>
  <div id="app">
    <Navbar v-if="noNavbarPage" />

		<div class="preloader">
			<div class="spinner"></div>
			<span>Sedang Memuat ...</span>
		</div>

    	<transition name="slide-fade">
    		<router-view/>
		</transition>
    <Footer />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'

export default {
  	components: {
  		Navbar, Footer
	},
	computed: {
  		noNavbarPage() {
			if(this.$route.name === 'Login' || this.$route.name === 'Register' || this.$route.name === 'ForgotPassword' || this.$route.name === 'NewPassword') {
				return false
			}
     		return true
  		}
	},
 	beforeCreate () {
		$(".preloader").fadeIn();
 	},
	created () {
		setTimeout(function(){
			$(".preloader").fadeOut();
			$("body").css("overflow", "scroll");
		}, 1000)
	},
}
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all 1s ease-in;
}
.slide-fade-leave-active {
  transition: all .5s ease-in-out;
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>