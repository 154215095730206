<template>
	<div>
		<!-- Floating Top Nav -->
		<div id="top_navAlert">
			<div class="alert alert-warning alert-dismissible fade show py-1 border-0 rounded-0 alert-top-nav fixed-top bg-light d-none d-md-block d-lg-block" role="alert">
				<ul class="list-inline m-0">
					<!-- <li class="list-inline-item float-left ml-5 dropright">
						<span class="dropdown-toggle" type="button" id="plaftorm_dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<i class="fa fa-mobile-alt fa-sm"></i> Unduh Aplikasi
						</span>
						<div class="dropdown-menu" aria-labelledby="plaftorm_dropdown">
							<a class="dropdown-item py-2 px-4" href="">
								<i class="fab fa-android fa-lg float-left mr-3 pr-1"></i>
								<h6 class="mb-0 mr-5"> Android</h6>
							</a>
							<a class="dropdown-item py-2 px-4" href="">
								<i class="fab fa-apple fa-lg float-left ml-1 mr-4"></i>
								<h6 class="mb-0 mr-5"> Apple</h6>
							</a>
						</div>
					</li> -->
					<li class="list-inline-item float-right mr-3">
						<a href="https://wa.me/06281310005877" target="_blank" class="text-decoration-none text-light">
							<i class="fab fa-whatsapp fa-sm"></i> Hubungi Kami
						</a>
						<!-- <router-link to="/static/call-center" class="text-decoration-none text-light">
							<i class="fab fa-whatsapp fa-sm"></i> Hubungi Kami
						</router-link> -->
					</li>
					<li class="list-inline-item float-right mr-4">
						<router-link to="/static/panduan-belanja" class="text-decoration-none text-light">
							<i class="fa fa-info-circle fa-sm"></i> Panduan
						</router-link>
					</li>
					<li class="list-inline-item float-right mr-4">
						<router-link to="/promo" class="text-decoration-none text-light">
							<i class="fa fa-percentage fa-sm"></i> Promo
						</router-link>
					</li>
				</ul>
				<button type="button" class="close pt-0" @click="close_topNav()">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="d-none d-md-block d-lg-block" style="position: relative; height: 32px" id="equalizer_topNavbar"></div>
		</div>
		<!-- Floating Navbar -->
		<nav class="navbar fixed-top navbar-expand-lg navbar-light bg-white shadow space-top-nav" id="top_Navbar">
			<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarToggler" aria-controls="navbarToggler" 	aria-expanded="false" aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
			</button>
    		<router-link to="/" class="navbar-brand d-block d-sm-block d-md-block d-lg-none d-xl-none"><img src="@/assets/images/logo.png" 	width="130px" /></router-link>
			<div class="collapse navbar-collapse justify-content-center" id="navbarToggler">
				<div class="row w-100 m-0 text-center text-center justify-content-center">
					<div class="col-md-3 d-none d-sm-none d-md-none d-lg-block pt-2">
    	      			<router-link to="/" class="navbar-brand"><img src="@/assets/images/logo.png" width="130px" /></router-link>
					</div>
					<div class="col-md-6 pt-3 pb-2 px-3">
						<form class="search-box" @submit.prevent="submitForm()" :action="'/product-list?type=product&q='+ keywordSearch" method="post">
							<div class="search-container">
								<input v-model.trim="keywordSearch" type="text" class="search-field" @keyup="autoComplete()" @focus="enter_autoComplete()" @blur="leave_autoComplete()" placeholder="Mau cari apa hari ini?" />
							</div>
							<div class="search-container search-cover">
								<button type="submit" class="search-button">
									<div class="search-circle"></div>
									<span></span>
								</button>
							</div>
						</form>
						<div style="position: absolute; left: 0; z-index: 1;" class="w-100 px-3" id="list_autoComplete">
							<div class="list-group text-left shadow max-content-user customScroolBar" style="max-height: 400px;">
								<label @click="act_autoComplete(prediction.prod_key_name)" class="list-group-item list-group-item-action px-4 py-2 mb-0" style="cursor: pointer;" v-for="prediction in auto_complete" :key="prediction.prod_key_id">{{ prediction.prod_key_name | capitalize }}</label>
							</div>
						</div>
					</div>
					<div class="col-md-3 pt-3 pb-2" v-if="isLoggedIn">
						<ul class="navbar-nav pt-1 justify-content-center d-none d-lg-block navbar-detail">
							<li class="nav-item active list-inline-item">
								<!-- <router-link to="/member/shopping-cart" class="nav-link py-0 px-1">
									<button class="btn btn-primary" id="nav-keranjang"><i class="fa fa-shopping-cart"></i></button>
								</router-link> -->
								<span class="badge badge-float badge-pill badge-danger badge-lg shadow border border-warning" v-if="jumlah_produk_keranjang > 0">{{ jumlah_produk_keranjang }}</span>
								<div class="sub-detail">
									<div class="row py-4 text-center round">
										<div class="col-md-12"><h5><i class="fa fa-shopping-cart"></i> Keranjang Belanja</h5><hr class="w-75" /></div>
										<div class="col-md-12">
											<div class="text-center my-3" v-if="isLoading">
												<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
											</div>
											<div v-else>
												<ul class="list-group list-group-flush customScroolBar" v-if="jumlah_produk_keranjang > 0">
													<li class="list-group-item" v-for="product in produk_keranjang" :key="product.prod_id">
														<div class="row w-100 m-0 d-flex justify-content-between align-items-center">
															<div class="col-lg-3">
																<router-link :to="'/product-detail/'+product.prod_id+'-'+product.prod_name.toLowerCase()" class="text-decoration-none">
																	<img v-lazy="productImage(product.prod_image)" @error="imageLoadError" class="card-img-top" :alt="product.prod_image" />
																</router-link>
															</div>
															<div class="col-lg-7 text-left px-1">
																<router-link :to="'/product-detail/'+product.prod_id+'-'+product.prod_name.toLowerCase()" class="text-decoration-none">
																	<h6 class="card-text text-secondary mb-1">{{ product.prod_name | str_limit(20) | capitalize }}</h6>
																</router-link>
																<div class="d-flex justify-content-start align-content-center" v-if="product.prod_discount && product.prod_discount != 0">
																	<h6 class="text-success mb-0">{{ discountedPrice(product.prod_price, product.prod_discount) | formatCurrency('IDR') }}</h6>
																	<span class="badge badge-pill badge-danger px-2 ml-2 pt-1">{{ product.prod_discount }}%</span>
																</div>
																<h6 class="text-success mb-0" v-else>{{ product.prod_price | formatCurrency('IDR') }}</h6>
																<p class="text-muted mb-0">{{ product.keranjang_prod_quantity }} Pcs</p>
															</div>
															<div class="col-lg-2">
																<button class="btn btn-outline-danger mb-1" @click="delete_productKeranjang(product.keranjang_id)"><i class="fa fa-times fa-sm"></i></button>
															</div>
														</div>
													</li>
												</ul>
												<div class="text-center my-3" v-else>
													<h5 class="text-info">Oops!</h5>
													<p class="text-muted">Belum ada Produk dalam Keranjang</p>
												</div>
											</div>
										</div>
										<div class="col-md-12"><hr class="w-75" />
											<router-link to="/member/shopping-cart" class="text-info text-decoration-none">Lihat Semua Keranjang <i class="fa fa-search-plus"></i></router-link>
										</div>
									</div>
								</div>
							</li>
							<li class="nav-item active list-inline-item">
								<router-link to="/member/favorite" class="nav-link py-0 px-1">
									<button class="btn btn-primary" id="nav-favorite"><i class="fa fa-heart"></i></button>
								</router-link>
								<span class="badge badge-float badge-pill badge-danger badge-lg shadow border border-warning" v-if="jumlah_produk_favorite > 0">{{ jumlah_produk_favorite }}</span>
								<div class="sub-detail">
									<div class="row py-4 text-center round">
										<div class="col-md-12"><h5><i class="fa fa-heart"></i> Produk Favorit</h5><hr class="w-75" /></div>
										<div class="col-md-12">
											<div class="text-center my-3" v-if="isLoading">
												<div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
											</div>
											<div v-else>
												<ul class="list-group list-group-flush customScroolBar" v-if="jumlah_produk_favorite > 0">
													<li class="list-group-item" v-for="product in produk_favorite" :key="product.wis_prod_id">
														<div class="row w-100 m-0 d-flex justify-content-between align-items-center">
															<div class="col-lg-3">
																<router-link :to="'/product-detail/'+product.wis_prod_id+'-'+product.prod_name.toLowerCase()" class="text-decoration-none">
																	<img v-lazy="productImage(product.prod_image)" @error="imageLoadError" class="card-img-top" :alt="product.prod_image" />
																</router-link>
															</div>
															<div class="col-lg-7 text-left px-1">
																<router-link :to="'/product-detail/'+product.wis_prod_id+'-'+product.prod_name.toLowerCase()" class="text-decoration-none">
																	<h6 class="card-text text-secondary mb-1">{{ product.prod_name | str_limit(20) | capitalize }}</h6>
																</router-link>
																<!-- <div class="d-flex justify-content-start align-content-center" v-if="product.prod_discount && product.prod_discount != 0">
																	<h6 class="text-success mb-0">{{ discountedPrice(product.prod_price, product.prod_discount) | formatCurrency('IDR') }}</h6>
																	<span class="badge badge-pill badge-danger px-2 ml-2 pt-1">{{ product.prod_discount }}%</span>
																</div>
																<h6 class="text-success mb-0" v-else>{{ product.prod_price | formatCurrency('IDR') }}</h6> -->
																<h6 class="text-success mb-0">-</h6>
															</div>
															<div class="col-lg-2">
																<button class="btn btn-outline-danger mb-1"  @click="delete_productFavorite(product.wis_id)"><i class="fa fa-times fa-sm"></i></button>
															</div>
														</div>
													</li>
													<li v-if="favorite_next" class="list-group-item bg-light">
														<h6><router-link to="/member/favorite" class="text-info text-decoration-none">Lihat Lainya <i class="fa fa-search-plus"></i></router-link></h6>
													</li>
												</ul>
												<div class="text-center my-3" v-else>
													<h5 class="text-info">Oops!</h5>
													<p class="text-muted">Belum ada Produk yang Favorit</p>
												</div>
											</div>
										</div>
										<div class="col-md-12"><hr class="w-75" />
											<router-link to="/member/favorite" class="text-info text-decoration-none">Lihat Semua Favorit <i class="fa fa-search-plus"></i></router-link>
										</div>
									</div>
								</div>
							</li>
							<li class="nav-item active list-inline-item">
								<button class="btn btn-outline-info" id="sidebarCollapse"><i class="fa fa-bars fa-lg"></i></button>
							</li>
						</ul>
						<ul class="navbar-nav justify-content-end d-lg-none">
							<div class="row">
								<div class="col-3 py-1 px-2">
									<li class="nav-item active">
										<router-link class="nav-link p-0" to="/member/shopping-cart"><button class="btn btn btn-outline-info"><i class="fa fa-shopping-cart"></i></button></router-link>
									</li>
								</div>
								<div class="col-3 py-1 px-2">
									<li class="nav-item">
										<router-link class="nav-link p-0" to="/member/favorite"><button class="btn btn btn-outline-info"><i class="fa fa-heart"></i></button></router-link>
									</li>
								</div>
								<div class="col-3 py-1 px-2">
									<li class="nav-item">
										<router-link class="nav-link p-0" to="/product/compare"><button class="btn btn btn-outline-info"><i class="fa fa-sliders-h"></i></button></router-link>
									</li>
								</div>
								<div class="col-3 py-1 px-2">
									<li class="nav-item">
										<router-link class="nav-link p-0" to="/member/purchase"><button class="btn btn btn-outline-info"><i class="fa fa-bell"></i></button></router-link>
									</li>
								</div>
								<div class="col-12 p-2">
									<li class="nav-item">
										<router-link class="nav-link p-0" to="/member/profile">
											<button class="btn btn btn-outline-info w-75 d-inline-flex align-items-center justify-content-center">
												<p class="m-0 pl-2">{{ userFullname | str_limit(15) | capitalize }}</p>
											</button>
										</router-link>
									</li>
								</div>
								<div class="col-12 p-2">
									<li class="nav-item">
										<button class="btn btn btn-outline-warning w-75" @click="logout"><i class="fa fa-power-off"></i> Keluar</button>
									</li>
								</div>
							</div>
						</ul>
					</div>
					<div class="col-md-3 pt-3 pb-2" v-else>
						<ul class="navbar-nav justify-content-center">
							<div class="row m-0">
								<div class="col-6 p-1">
									<li class="nav-item">
										<router-link to="/login" class="nav-link p-0"><button class="btn btn-primary w-100">Masuk</button></router-link>
									</li>
								</div>
								<div class="col-6 p-1">
									<li class="nav-item">
										<router-link to="/register" class="nav-link p-0"><button class="btn btn-success w-100">Daftar</button></router-link>
									</li>
								</div>
							</div>
						</ul>
					</div>
				</div>
			</div>
		</nav>
		
		<!-- Floating Button Bottom -->
		<a href="https://wa.me/06281310005877" target="_blank">
			<img class="float" src="@/assets/images/whatsapp.png" />
		</a>
		
		<!-- Modal ADS -->
		<div class="modal fade" id="modalAds" tabindex="-1" role="dialog" aria-labelledby="modalAdsTitle" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered" role="document">
				<div class="modal-content">
					<div class="modal-body">
						<button type="button" class="close px-2" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
						<div>
							<small class="text-muted">Kode Kupon: </small>
							<div class="d-flex align-items-center justify-content-start">
								<h3 class="text-info mb-0 mr-3"><strong>HARBOLNAS</strong></h3>
								<button class="btn btn-outline-info" data-toggle="tooltip" data-placement="top" title="Salin Kode Kupon" v-clipboard:copy="kodeKupon" @click="doCopy"><i class="far fa-copy fa-lg"></i></button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div v-if="isLoggedIn">
			<!-- Sidebar -->
			<nav id="sidebar" class="d-none d-lg-block">
				<div class="sidebar-header mt-3 mx-4 ">
					<button class="btn btn btn-info float-left" id="dismiss"><i class="fa fa-bars fa-lg"></i></button>
					<h5 class="my-2 ml-5 pl-3">Menu</h5>
				</div>
				<hr class="my-0 w-75" />
				<div class="w-100 pt-4 pb-1 px-2 d-flex align-items-center justify-content-center">
					
					<img v-lazy="profileImage(userPhoto)" style="width: 75px; height: 75px; overflow: hidden;" :alt="userPhoto" class="rounded-circle shadow border" @error="imageLoadError" />
					
					<div class="pl-2"><p class="m-0">{{ userFullname | str_limit(15) | capitalize }}</p>
						<router-link to="/member/profile" class="text-decoration-none text-info" id="dismiss">Profil</router-link>
					</div>
				</div>
				<ul class="components nav flex-column sidebar-nav">
					<!-- <li class="list-group-item">
						<router-link to="/member/shopping-cart" class="nav-link d-flex justify-content-between align-items-center py-0" id="dismiss">
							<span><i class="fa fa-shopping-cart pr-2"></i> Keranjang</span> <span class="badge badge-pill badge-info badge-lg" v-if="jumlah_produk_keranjang > 0">{{ jumlah_produk_keranjang }}</span>
						</router-link>
					</li> -->
					<li class="list-group-item">
						<router-link to="/member/favorite" class="nav-link d-flex justify-content-between align-items-center py-0" id="dismiss">
							<span><i class="fa fa-heart pr-2"></i> Favorit</span> <span class="badge badge-pill badge-info badge-lg" v-if="jumlah_produk_favorite">{{ jumlah_produk_favorite }}</span>
						</router-link>
					</li>
					<li class="list-group-item">
						<router-link class="nav-link py-0" to="/product/compare" id="dismiss"><i class="fa fa-sliders-h pr-2"></i> Bandingkan Produk</router-link>
					</li>
					<!-- <li class="list-group-item">
						<router-link to="/member/purchase" class="nav-link d-flex justify-content-between align-items-center py-0" id="dismiss">
							<span><i class="fa fa-cart-arrow-down pr-2"></i> Transaksi</span> <span class="badge badge-pill badge-info badge-lg" v-if="jumlah_transaksi > 0">{{ jumlah_transaksi }}</span>
						</router-link>
					</li> -->
					<!-- <li class="list-group-item">
						<router-link to="/member/bargaining" class="nav-link d-flex justify-content-between align-items-center py-0" id="dismiss">
							<span><i class="fa fa-sort-amount-down pr-2"></i> Nego Harga</span> <span class="badge badge-pill badge-info badge-lg" v-if="jumlah_nego > 0">{{ jumlah_nego }}</span>
						</router-link>
					</li> -->
					<!-- <li class="list-group-item">
						<router-link to="/member/review" class="nav-link py-0" id="dismiss"><i class="fa fa-pencil-alt pr-2"></i> Ulasan</router-link>
					</li>	 -->
				</ul>
				<div class="w-100 py-2 d-flex justify-content-center">
					<button class="btn btn btn-warning w-75 px-4" @click="logout" id="dismiss"><i class="fa fa-power-off"></i> Keluar</button>
				</div>
			</nav>
			<!-- Overlay Sidebar -->
			<div class="overlay d-none d-lg-block"></div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'

export default {
	name: 'Navbar',
	data() {
		return {
			keywordSearch: '',
			jumlah_produk_keranjang: 0,
			produk_keranjang: '',
			jumlah_produk_favorite: 0,
			favorite_next: false,
			produk_favorite: '',
			jumlah_transaksi: 0,
			jumlah_nego: 0,
			auto_complete: [],
			userFullname: '',
			userPhoto: '',
			kodeKupon: 'HARBOLNAS',
		}
	},
	computed: {
      	isLoggedIn : function() {
      	  	return this.$store.getters.isLoggedIn
      	},
		isLoading() {
			return this.$store.getters.isLoading
		},
      	// userFullname : function() {			
		// 	if(this.$store.getters.name_member) {
		// 		return this.$store.getters.name_member
		// 	}
		// 	return localStorage.getItem('name_member')
		// },
      	// userPhoto : function() {
		// 	if(this.$store.getters.photo_profile) {
		// 		return this.$store.getters.photo_profile
		// 	}
		// 	return localStorage.getItem('photo_profile')
		// },
	},
	methods: {
		show_topNav() {
			$('#top_Navbar').css('top', '30px')
			$('#top_navAlert').show()
		},
		close_topNav() {
			$('#top_Navbar').css('top', '0')
			$('#top_navAlert').hide()
		},
		doCopy() {
			this.$swal({
				toast: true,
				timer: 3000,
				position: 'top-end',
				showConfirmButton: false,
				icon: 'success',
				html: '<p class="mb-0 mx-3">Kode Kupon berhasil disalin.</p>',
				customClass: {
					container: 'mt-5 pt-5',
					popup: 'border border-success mt-5 px-4',
				}
			})
		},
		get_userComponentData() {
			// GET NAMA MEMBER
			if(this.$store.getters.name_member) {
				this.userFullname = this.$store.getters.name_member
			}
			else {
				this.userFullname = localStorage.getItem('name_member')
			}

			// GET FOTO PROFIL MEMBER
			if(this.$store.getters.photo_profile) {
				this.userPhoto = this.$store.getters.photo_profile
			}
			else {
				this.userPhoto = localStorage.getItem('photo_profile')
			}
		},
		submitForm() {
			if(!this.keywordSearch) {
				this.keywordSearch = 'Semua Produk'
			}

			this.$router.push('/product-list?type=product&q='+ this.keywordSearch)
			.then(() => {
      	  	  	this.keywordSearch = ''
			})
			.catch(
				err => { this.$router.go() }
			)
		},
		get_productKeranjang() {
			window.axios.get('/cart', {
       		        headers: {
						"token": this.$store.getters.token_member
       		        }
				}
			)
       		.then(response => {
       		    if(response.data.success === true) {
					this.produk_keranjang = response.data.data
				}
				this.jumlah_produk_keranjang = this.produk_keranjang.length

				$('#nav-keranjang').addClass('animate__animated animate__heartBeat');
				let timeout = null
				timeout = setTimeout(() => {
					$('#nav-keranjang').removeClass('animate__animated animate__heartBeat');
				}, 5000);
       		})
       		.catch(error => {
       			console.log(error)
			});
		},
		get_productFavorite() {
			window.axios.get('/wishlist', {
       		        headers: {
						"token": this.$store.getters.token_member
       		        }
				}
			)
       		.then(response => {
       		    if(response.data.success === true) {
					this.produk_favorite = response.data.data.data
				}
				this.jumlah_produk_favorite = response.data.data.total
				this.favorite_next = false
				if(response.data.data.total > this.jumlah_produk_favorite) {
					this.favorite_next = true
				}

				$('#nav-favorite').addClass('animate__animated animate__heartBeat');
				let timeout = null
				timeout = setTimeout(() => {
					$('#nav-favorite').removeClass('animate__animated animate__heartBeat');
				}, 5000);
       		})
       		.catch(error => {
       			console.log(error)
			});
		},
		// get_transactionCounts() {
			// window.axios.get('/transaction', {
			// 		headers: {
			// 			"token": this.$store.getters.token_member
			// 		}
			// 	}
			// )
			// .then(response => {
			// 	this.jumlah_transaksi = response.data.data.length
			// })
			// .catch(error => {
			// 	console.log(error)
			// })
		// },
		// get_negoCounts() {
			// window.axios.get('/nego', {
       		//         headers: {
       		//             "token": this.$store.getters.token_member
       		//         }
       		//     }
       		// )
       		// .then(response => {
			// 	this.jumlah_nego = response.data.data.length
       		// })
       		// .catch(error => {
       		// 	console.log(error)
			// });
		// },
		delete_productKeranjang(id) {
			window.axios.delete('/cart/'+ id, {
                    headers: {
						"Content-Type": "application/x-www-form-urlencoded",
						"token": this.$store.getters.token_member
                    }
                }
            )
 			.then((response) => {
				this.get_productKeranjang()
				try {
					window.productKeranjangDirtyAccess.get_productKeranjang()
				}
				catch(error) {}
 			})
 			.catch((error) => {
 				console.log(error)
			})
		},
		delete_productFavorite(id) {
			window.axios.delete('/wishlist/'+ id, {
                    headers: {
						"Content-Type": "application/x-www-form-urlencoded",
						"token": this.$store.getters.token_member
                    }
                }
            )
 			.then((response) => {
				this.get_productFavorite()
				try {
					window.productFavoriteDirtyAccess.get_productFavorite_first()
				}
				catch(error) {}
 			})
 			.catch((error) => {
 				console.log(error)
			})
		},
		autoComplete() {
			if(this.keywordSearch.length > 2) {
			
				// JANGAN GUNAKAN AXIOS UNTUK AKSES INI... AXIOS TRIGGER _axios.interceptors.request YANG OTOMATIS AKAN MENGAKTIFKAN state isLoading
				fetch('http://devdm.anyoneserver.com/catalogue/keyword?q='+ this.keywordSearch)
				.then(response => response.json())
				.then(data => this.auto_complete = data.data)
			}
			this.auto_complete = []
		},
		act_autoComplete(auto_complete) {
			this.keywordSearch = auto_complete.toLowerCase()
			this.auto_complete = []
			this.submitForm()
		},
		enter_autoComplete() {
			$('#list_autoComplete').show()
		},
		leave_autoComplete() {
			setTimeout(()=>{
				$('#list_autoComplete').hide()
			}, 400)
		},
      	logout() {
      	  	this.$store.dispatch('logout')
      	  	.then(() => {
      	  	  	this.$router.push('/login')
			})
			.catch(error => console.log(error))
		},
	},
	created() {
		if(this.isLoggedIn) {
			this.get_userComponentData()
			this.get_productKeranjang()
			this.get_productFavorite()
			// this.get_transactionCounts()
			// this.get_negoCounts()
			window.NavbarDirtyAccess = this

			Vue.nextTick(function(){
				$('#dismiss, .overlay').on('click', function () {
    			    $('#sidebar').removeClass('active')
    			    $('.overlay').removeClass('active')
    			})

    			$('#sidebarCollapse').on('click', function () {
    			    $('#sidebar').addClass('active')
    			    $('.overlay').addClass('active')
    			    $('a[aria-expanded=true]').attr('aria-expanded', 'false')
				})
    		})
		}
		else {
			this.$store.dispatch('logout')
		}
	},
	watch: {
    	'$route': function () {
			if(this.$route.name == 'Home') {
				this.show_topNav()
				
				// $('#modalAds').modal('hide')
				
				// GET waktu saat ini
				// var currentDate = new Date();
				
				// if(!!localStorage.getItem('time_ads')) {
				//	GET waktu dari local storage
				//	var waktu_localStorage = JSON.parse(localStorage.getItem('time_ads'))
					
				//	CEK apakah rentang waktu waktu_localStorage telah sepuluh menit?
				//	if((waktu_localStorage + 5*60000) <= currentDate.getTime()) {
				//		Jika ya, maka munculkan modalAds
				//		$('#modalAds').modal('show')
				//		SET waktu saat ini kadalam local storage
				//		localStorage.setItem('time_ads', currentDate.getTime())
				//	}
				//}
				// else {
				//		$('#modalAds').modal('show')
				//		SET waktu saat ini kadalam local storage
				//		localStorage.setItem('time_ads', currentDate.getTime())
				// }
				
				// Vue.nextTick(function(){
				// 	$('[data-toggle="tooltip"]').tooltip()
				// })
			}
		}
  	},
}
</script>
