import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import store from "./store.js";
import "./assets/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/css/style.css";
import "./assets/js/popper.min.js";
import "./assets/js/jquery.zoom.min.js";
import "./assets/js/owl.carousel.min.js";
import "./assets/js/custom.js";
import VueSweetalert2 from "vue-sweetalert2";
import PaginationPlugin from "bootstrap-vue";
import VueSession from "vue-session";
import VueLazyload from "vue-lazyload";
import VueClipboard from "vue-clipboard2";

Vue.config.productionTip = false;

/*
 *   [START] INISIALISASI DEPENDENSI
 */

// INISIALISASI & KONFIGURASI DEPENDENSI LAZY LOAD (UNTUK GAMBAR)
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: require("./assets/images/notfound.png"), // TERPANGGIL KETIKA GAMBAR GAGAL DI LOAD
  loading: require("./assets/images/loading.webp"), // TERPANGGIL KETIKA GAMBAR SEDANG DI LOAD
  attempt: 1,
});

// INISIALISASI & KONFIGURASI DEPENDENSI MOMENT.JS (UNTUK PENGATURAN WAKTU)
const moment = require("moment");
require("moment/locale/id");
Vue.use(require("vue-moment"), {
  moment,
});

// INISIALISASI DEPENDENSI SWEET ALERT VERSI 2 (UNTUK PESAN SUKSES/ERROR/WARNING/INFO)
Vue.use(VueSweetalert2);

// INISIALISASI DEPENDENSI PAGINASI (UNTUK HALAMAN PRODUK) SECARA CLIENT SIDE
Vue.use(PaginationPlugin);

// INISIALISASI DEPENDENSI COPY (UNTUK MEMPERMUDAH DALAM MENGCOPY SEBUAH TEXT DARI WEBSITE)
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

// INISIALISASI & KONFIGURASI DEPENDENSI SESSION (UNTUK MENYIMPAN DATA SECARA SEMENTARA)
Vue.use(VueSession);
var options = {
  persist: true,
};
Vue.use(VueSession, options);

/*
 *   [END] INISIALISASI DEPENDENSI
 */

/*
 *   [START] INISIALISASI PROPERTI FILTER SECARA GLOBAL
 */

// FUNGSI UNTUK MENGUBAH FORMAT UTC MENJADI FORMAT LOKAL INDONESIA DENGAN BANTUAN DEPENDENSI MOMENT.JS
Vue.filter("momentFormatID", function(date_utc) {
  let date = Vue.moment
    .utc(date_utc)
    .local()
    .format("LLLL"); // LEBIH LENGKAP TERKAIT FORMAT BACA DI https://momentjs.com/
  // let date = Vue.moment(date_utc).format('LLLL')
  return date;
});

// FUNGSI UNTUK MENGUBAH NOMINAL ANGKA (INT) MENJADI FORMAT RUPIAH (IDR)
Vue.filter("formatCurrency", function(value, currency) {
  var formatter = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 0,
  });
  return formatter.format(value);
});

// FUNGSI UNTUK MEMBATASI KARAKTER (STRING) YANG AKAN DITAMPILKAN
Vue.filter("str_limit", function(value, size) {
  if (!value) return "";
  value = value.toString();
  if (value.length <= size) {
    return value;
  }
  return value.substr(0, size) + "..."; // KETIKA KARAKTER (STRING) LEBIH DARI NILAI size YANG DIBERKAN MAKA AKAN MENGEMBALIKAN '...'
});

// FUNGSI UNTUK MERUBAH SETIAP HURUF PERTAMA PADA KATA MENJADI HURUP KAPITAL (Capitalize Earch Word)
Vue.filter("capitalize", function(text) {
  if (!text) return "";
  text = text.toString();
  return text
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
});

// FUNGSI UNTUK MERUBAH SELURUH HURUF MENJADI HURUF KAPITAL
Vue.filter("uppercase", function(text) {
  if (!text) return "";
  text = text.toString();
  return text.toUpperCase();
});

/*
 *   [END] INISIALISASI PROPERTI FILTER SECARA GLOBAL
 */

/*
 *   [START] INISIALISASI DIRECTIVE
 */

// DIRECTIVE UNTUK OTOMATIS MENGKAPITALKAN KARAKTER
Vue.directive("uppercase", {
  update: function(el) {
    el.value = el.value.toUpperCase();
  },
});
// DIRECTIVE UNTUK OTOMATIS MENGUBAH ANGKA (INT) MENJADI FORMAT RUPIAH (IDR)
Vue.directive("formatIDR", {
  update: function(el) {
    var formatter = new Intl.NumberFormat("id-ID", {
      currency: "IDR",
      minimumFractionDigits: 0,
    });
    el.value = formatter.format(el.value.replace(/\D/g, ""));
  },
});

/*
 *   [END] INISIALISASI DIRECTIVE
 */

// BASE URL
// var base_url = "http://devdm.anyoneserver.com"; // UNTUK DEVELOPMENT
var base_url = 'https://api.duniamurah.com'; // UNTUK PRODUCTION

/*
 *   [START] INISIALISASI PROPERTY METHODS
 */

Vue.mixin({
  methods: {
    // FUNGSI UNTUK MENANPILKAN GAMBAR DEFAULT (notfount.jpg) KETIKA GAMBAR UTAMA GAGAL DILOAD
    imageLoadError: function(event) {
      if (event.type == "error") {
        $(event.srcElement).trigger("zoom.destroy");
        event.target.src = require("./assets/images/notfound.png");
      }
    },
    // FUNGSI UNTUK MENGHITUNG HARGA DISKON PADA PRODUK
    discountedPrice(price, discount) {
      return price - (price * discount) / 100;
    },
    // FUNGSI UNTUK MENGEMBALIKAN URL IMAGE BANNER
    bannerImage(image) {
      try {
        let [name, ext] = image.split(".");

        if (name && ext) {
          return base_url + "/assets/images/banner/thumbnail/" + name + "_705_370.webp";
          // return base_url +'/assets/collections/banner/img/'+ image
        }

        return require("./assets/images/notfound.png");
      } catch (e) {
        return require("./assets/images/notfound.png");
      }
    },
    // FUNGSI UNTUK MENGEMBALIKAN URL IMAGE BRAND
    manufactureImage(image) {
      try {
        let [name, ext] = image.split(".");

        if (name && ext) {
          return base_url + "/assets/images/brand/thumbnail/" + name + "_350_350.webp";
          // return base_url +'/assets/collections/brand/img/'+ image
        }

        return require("./assets/images/notfound.png");
      } catch (e) {
        return require("./assets/images/notfound.png");
      }
    },
    // FUNGSI UNTUK MENGEMBALIKAN URL IMAGE CATEGORY
    catagoryImage(image) {
      try {
        let [name, ext] = image.split(".");

        if (name && ext) {
          return base_url + "/assets/images/category/thumbnail/" + name + "_310_310.webp";
          // return base_url +'/assets/collections/category/img/'+ image
        }

        return require("./assets/images/notfound.png");
      } catch (e) {
        return require("./assets/images/notfound.png");
      }
    },
    // FUNGSI UNTUK MENGEMBALIKAN URL IMAGE PROMO
    imagePromo(image) {
      try {
        let [name, ext] = image.split(".");

        if (name && ext) {
          return base_url + "/assets/images/promo/thumbnail/" + name + "_290_130.webp";
          // return base_url +'/assets/collections/promo/img/'+ image
        }

        return require("./assets/images/notfound.png");
      } catch (e) {
        return require("./assets/images/notfound.png");
      }
    },
    // FUNGSI UNTUK MENGEMBALIKAN URL IMAGE HASIL RESIZING UNTUK PRODUCT
    productImage(image) {
      try {
        let [name, ext] = image.split(".");

        if (name && ext) {
          return base_url + "/assets/images/catalogue/thumbnail/" + name + "_350_350.webp";
        }

        return require("./assets/images/notfound.png");
      } catch (e) {
        return require("./assets/images/notfound.png");
      }
    },
    // FUNGSI UNTUK MENGEMBALIKAN URL IMAGE DENGAN UKURAN ASLI UNTUK PRODUCT
    product_imageReal(image) {
      return base_url + "/assets/collections/catalogue/img/" + image;
    },
    // FUNGSI UNTUK MENGEMBALIKAN URL IMAGE ITEM DENGAN UKURAN ASLI UNTUK PRODUCT
    product_imageItems(image) {
      return base_url + "/assets/collections/catalogue/item/" + image;
    },
    // FUNGSI UNTUK MENGEMBALIKAN URL IMAGE FOTO PROFIL
    profileImage(image) {
      if (image != "null" && image != "" && image != undefined) {
        try {
          let [name, ext] = image.split(".");

          if (name && ext) {
            return base_url + "/assets/images/user/thumbnail/" + name + "_300_300.webp";
            // return base_url +'/assets/collections/user/'+ image
          }
          return base_url + "/assets/collections/user/profile-kosong.png";
        } catch (e) {
          return base_url + "/assets/collections/user/profile-kosong.png";
        }
      } else {
        return base_url + "/assets/collections/user/profile-kosong.png";
      }
    },
  },
});
/*
 *   [END] INISIALISASI PROPERTY METHODS
 */

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
